import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { ArrowLeftIcon, Attachment, Avatar, Button, Card, Chat, Divider, EmojiIcon, Flex, Image, Menu, menuAsToolbarBehavior, MoreIcon, PaperclipIcon, Pill, PresenceAvailableIcon, QuestionCircleIcon, SendIcon, SettingsIcon, Text, TextArea, TrashCanIcon } from '@fluentui/react-northstar'
import { InfiniteData, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import Changelog from "Assets/Changelog.svg"
import { ConversationModel, MessageModel, PageModel, Props, ResponseModel } from 'Models'
import { MessageService } from 'Services'
import { concat, filter, findIndex, first, forEach, groupBy, last, map, sortBy } from 'lodash'
import moment from 'moment'
import 'moment/locale/nl'
import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { BrowserView, MobileOnlyView } from 'react-device-detect'
import { socket } from 'socket'
import { ChangeLogDialog } from './ChangeLogDialog'
import { FeedbackDialog } from './FeedbackDialog'
import FileIcon from './FileIcon'
import { MessageContent } from './MessageContent'
import { SendTemplateMessage } from './SendTemplateMessage'
import { SettingDialog } from './SettingDialog'

moment.locale('nl');
const MessageBox: FC<Props & { selected: ConversationModel, onBackClick: () => void }> = (props) => {
    const { t } = props;
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const messageBoxRef = useRef<HTMLDivElement>(null);
    const inputState = useRef<{
        actionTriggered: boolean,
        lastScrollTop: number
    }>({
        actionTriggered: false,
        lastScrollTop: 0
    });
    const lastMessageRef = useRef<HTMLDivElement>(null);
    const { selected, onBackClick } = props;
    const [state, setState] = useState<{
        emoji: boolean,
        dialog: 'changeLog' | 'setting' | 'feedback' | ''
    }>({
        emoji: false,
        dialog: ''
    });

    const queryClient = useQueryClient();
    const messageQuery = useInfiniteQuery({
        initialPageParam: 1,
        queryKey: ['messages', selected?.uuid],
        queryFn: ({ pageParam }) => MessageService.messages({
            senderId: selected?.client?.uuid,
            page: pageParam,
            limit: 50
        }),
        getNextPageParam: (lastPage) => {
            return lastPage.data.lastPage > lastPage.data.currentPage ? lastPage.data.currentPage + 1 : undefined
        },
        select: (data): Array<MessageModel> => {
            return sortBy(concat(...map(data.pages, page => page.data.items || [])), 'timestamp');

        },
    })

    const mutation = useMutation({
        mutationFn: (params: FormData) => MessageService.sendMessage(params),
    })

    const [message, setMessage] = useState<{
        clientId: string,
        content: string,
        type: string,
        files: FileList | null,
        error: boolean,
    }>({
        error: false,
        clientId: '',
        content: '',
        type: '',
        files: null,
    });
    const scrollToView = () => {
        if (inputState.current.actionTriggered && messageQuery.isFetched) {
            inputState.current.actionTriggered = false;
            return
        }
        document.getElementById('ui-chat')?.scrollTo({ top: document.getElementById('ui-chat')?.scrollHeight || 0 });
    }
    useEffect(() => {
        messageBoxRef.current?.addEventListener("scroll", () => {
            if (messageBoxRef.current) {
                const { scrollTop } = messageBoxRef.current;

                const threshold = 70;
                const atTop = scrollTop === 0 || scrollTop <= (messageBoxRef.current.scrollHeight - messageBoxRef.current.clientHeight) * (threshold / 100);

                if (inputState.current.actionTriggered) {
                    return;
                }
                if (atTop && scrollTop > 0) {
                    inputState.current.lastScrollTop = scrollTop;
                    messageQuery.fetchNextPage();
                    inputState.current.actionTriggered = true;
                }
            }
            return;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        scrollToView()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageQuery.data?.length])
    useEffect(() => {
        const emojiDiv = document.getElementsByTagName('em-emoji-picker')
        const handleClickOutside = (event: any) => {
            if (emojiDiv.length && !emojiDiv[0]?.contains(event.target)) {
                toggleEmoji()
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        if (!selected) {
            return
        }
        if (!props?.user) {
            return
        }
        socket.on(`${props?.user?.tenantId}/${selected.uuid}/new:message`, (data: { header: { senderId: string }, body: MessageModel }) => {
            if (data.header.senderId !== props?.user?.uuid && data.body.conversationId === selected?.uuid) {
                socket.emit(`read:message`, { header: { senderId: props?.user?.uuid }, body: data.body })
            }
            if (data.body.parentId) {
                queryClient.setQueryData(['messages', selected.uuid], (prevState: InfiniteData<ResponseModel<PageModel<MessageModel>>>) => {
                    if (!prevState) {
                        return
                    }
                    prevState.pages = [...map(prevState.pages, page => {
                        page.data.items = map(page.data.items, message => {
                            if (message.uuid === data.body.parentId) {
                                if (!Array.isArray(message.posts)) {
                                    message.posts = []
                                }
                                message.posts?.push(data.body)
                            }
                            return message
                        })
                        return page;
                    })]
                    return { ...prevState };
                })
                return;
            }

            queryClient.setQueryData(['messages', selected.uuid], (prevState: InfiniteData<ResponseModel<PageModel<MessageModel>>>) => {
                if (!prevState) {
                    return
                }
                prevState.pages = [...map(prevState.pages, page => {
                    if (page.data.currentPage === 1) {
                        page.data.items = [data.body, ...page.data.items]
                    }
                    return page;
                })]
                return { ...prevState };
            })
            setState((prevState) => {
                return { ...prevState };
            })
            scrollToView();
        })
        socket.on(`${props?.user.tenantId}/${selected.uuid}/update:message`, (data: { header: { senderId: string }, body: MessageModel }) => {
            queryClient.setQueryData(['messages', selected.uuid], (prevState: InfiniteData<ResponseModel<PageModel<MessageModel>>>) => {
                if (!prevState) {
                    return
                }
                prevState.pages = [...map(prevState.pages, page => {
                    page.data.items = map(page.data.items, message => {
                        if (message.uuid === data.body.uuid) {
                            return data.body
                        }
                        return message
                    })
                    return page;
                })]
                return { ...prevState };
            })
            scrollToView()
        })
        socket.connect();


        return () => {
            socket.off(`${props?.user?.tenantId}/${selected?.uuid}/new:message`)
            socket.off(`${props?.user?.tenantId}/${selected?.uuid}/update:message`)
            socket.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected?.uuid, props?.user?.tenantId]);

    const handleInputChange = (event: any, { value }: any) => {
        setMessage(prevState => {
            prevState.clientId = selected?.client?.uuid || '';
            prevState.type = 'text'
            prevState.content = event.target.value;
            return { ...prevState }
        })
    }

    const handleMessageKeyDown = (event: KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter' && event.shiftKey === false) {
            sendMessage()
            event.preventDefault();
        }
    }
    const latestMessage = last(filter(messageQuery.data || [], (msg: MessageModel) => msg?.senderId === selected?.client?.uuid));
    const isNotActive = latestMessage ? moment(latestMessage.timestamp * 1000).isBefore(moment().subtract(24, 'hours')) : true;
    const sendMessage = () => {
        if (isNotActive) {
            return;
        }
        if (!message.files?.length && !message.content) {
            setMessage(prevState => {
                prevState.error = true
                return { ...prevState }
            })
            return
        }
        const params = new FormData();
        params.append('clientId', message.clientId);
        params.append('content', message.content);
        params.append('type', message.type);
        if (message.files) {
            for (let i = 0; i < message.files.length; i++) {
                params.append('files', message.files[i]);
            }
        }
        setMessage(prevState => {
            prevState.content = '';
            prevState.error = false
            prevState.files = null
            prevState.type = 'text'
            return { ...prevState }
        })
        mutation.mutate(params)
    }

    const handleUploadFile = () => {
        const element = document.createElement('input');
        element.type = 'file';
        element.multiple = false;
        element.onchange = () => {
            if (element.files) {
                setMessage(prevState => {
                    prevState.clientId = selected?.client?.uuid || '';
                    prevState.files = element.files
                    return { ...prevState }
                })
                inputRef.current?.focus()
            }
        }
        element.accept = ".jpg, .jpeg, .png, .gif, .mp4, .mp3, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx"
        element.click();
    }

    const handleFileDelete = () => {
        setMessage(prevState => {
            prevState.files = null
            return { ...prevState }
        })
    }

    const toggleEmoji = () => {
        setState((prevState) => {
            prevState.emoji = !prevState.emoji
            return { ...prevState }
        })
    }

    const handleEmojiPicker = (emoji: any) => {
        setMessage(prevState => {
            prevState.content += emoji.native;
            prevState.clientId = selected?.client?.uuid || '';
            prevState.type = 'text';
            return { ...prevState }
        })
        inputRef.current?.focus();
        toggleEmoji();
    }

    const formatMessageItem = (messages: Array<MessageModel>) => {
        const items: any = []
        map(groupBy(messages, (item: MessageModel) => {
            return moment(item.timestamp * 1000).format('YYYY-MM-DD')
        }), (messages, date) => {

            const lastMessage = first(messages);
            forEach(messages, (item: MessageModel, idx: number) => {
                if (item.uuid === lastMessage?.uuid) {
                    items.push({
                        children: <Divider content={lastMessage?.timestamp ? moment(lastMessage?.timestamp * 1000).calendar({
                            lastWeek: `[${t('LABEL.LAST_WEEK')}] dddd`,
                            lastDay: `[${t('LABEL.LAST_DAY')}]`,
                            sameDay: `[${t('LABEL.SAME_DAY')}]`,
                            sameElse: `DD/MM/YYYY`
                        }) : ''} />,
                        key: `${date}-divider-${idx}`,
                    })
                }
                const reactions = map(item.posts, (reaction) => {
                    return {
                        key: reaction.uuid,
                        content: reaction.content
                    }
                })
                const time = moment(item?.timestamp * 1000).format('HH:mm');
                const userName = `${item?.user?.firstName || ''} ${item?.user?.lastName || ''}`;
                items.push({
                    gutter: item.senderId === selected?.client?.uuid ? (<Avatar name={selected?.client?.firstName} style={{ color: '#fff', backgroundColor: selected?.client?.color, borderRadius: '50%' }} />) : null,
                    message: (
                        <Chat.Message
                            ref={item.uuid === lastMessage?.uuid ? lastMessageRef : null}
                            className={item.senderId === selected?.client?.uuid ? 'client-message' : 'user-message'}
                            reactionGroup={reactions}
                            reactionGroupPosition='end'
                            content={<MessageContent message={item} onLoad={() => { }} t={t} />}
                            author={userName}
                            timestamp={item.senderId !== selected?.client?.uuid ? (<><Text weight='semibold'>{userName}</Text> {time}</>) : time}
                            mine={item.senderId !== selected?.client?.uuid}
                            readStatus={item.senderId === props?.user?.uuid && item.status === 'READ' ? {
                                title: 'Gelezen',
                                content: <PresenceAvailableIcon size="small" color='green' />
                            } : null}
                        />
                    ),
                    contentPosition: item.senderId === selected?.client?.uuid ? 'start' : 'end',
                    attached: messages[idx - 1] && messages[idx - 1]?.senderId === item.senderId && (item.timestamp - messages[idx - 1]?.timestamp) < 60 ? 'bottom' : 'top',
                    key: item?.uuid,
                })

            })
        })
        return items;
    }

    const handleDialogOpen = (dialog: 'changeLog' | 'setting' | 'feedback') => {
        setState((prevState) => {
            prevState.dialog = dialog;
            return { ...prevState };
        });
    }

    const handleDialogClose = () => {
        setState((prevState) => {
            prevState.dialog = '';
            return { ...prevState };
        });
    }

    const hasAccess = findIndex(selected?.users, (user) => user?.uuid === props?.user?.uuid) > -1
    return (
        <Flex column className='message-box'>
            {/* {messageQuery.isFetchingNextPage && <Flex.Item>
                <Loader className='lezy-loader' />
            </Flex.Item>} */}
            {selected ? (<Flex padding='padding.medium' space='between'>
                <Flex.Item>
                    <Card.Header className='ui-chart-header' fitted>
                        <Flex gap="gap.small">
                            <Avatar name={selected?.client?.firstName} style={{ color: '#fff', backgroundColor: selected?.client?.color, borderRadius: '50%' }} />
                            <Flex column>
                                <Text content={
                                    <>
                                        {selected?.client?.firstName || ''} {selected?.client?.lastName || ''} &nbsp;
                                        <span style={{ opacity: .5, fontWeight: 500 }}>+{selected?.client?.phone || ''}</span>
                                    </>
                                } weight="bold" />
                                <Text content={map(selected?.users, (user, idx) => (
                                    <Pill key={idx} size='small'>
                                        {`${user?.firstName || ''} ${user?.lastName || ''}`}
                                    </Pill>
                                ))} />
                            </Flex>
                        </Flex>
                        {window.process?.env?.NODE_ENV === 'development' && <Flex gap="gap.small" style={{ position: 'absolute', left: '50%' }}>
                            <Text success weight='bold' size="large" content="Development" />
                        </Flex>}
                    </Card.Header>

                </Flex.Item>
                <Flex.Item>
                    <Flex gap='gap.small'>
                        <MobileOnlyView>
                            <Button onClick={onBackClick} primary icon={<ArrowLeftIcon />} text></Button>
                        </MobileOnlyView>
                        <BrowserView>
                            <Flex gap='gap.small'>
                                <Flex.Item>
                                    <FeedbackDialog {...props} open={state.dialog === 'feedback'} onClose={() => handleDialogClose()} />
                                </Flex.Item>
                                <Flex.Item>
                                    <ChangeLogDialog {...props} open={state.dialog === 'changeLog'} onClose={() => handleDialogClose()} />
                                </Flex.Item>
                                <Flex.Item>
                                    <SettingDialog {...props} open={state.dialog === 'setting' && props?.user?.role === 'ADMIN'} onClose={() => handleDialogClose()} />
                                </Flex.Item>
                                <Flex.Item>
                                    <Menu
                                        defaultActiveIndex={0}
                                        items={[
                                            {
                                                content: (
                                                    <Button className="change-log-btn" icon={<Image
                                                        style={{ margin: '0 0 0 5px', height: '18px' }}
                                                        src={Changelog}
                                                    />} circular primary></Button>
                                                ),
                                                key: 'changeLog',
                                                indicator: false,
                                                onClick: (e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleDialogOpen('changeLog')
                                                }
                                            },
                                            {
                                                content: (
                                                    <Button primary circular icon={<MoreIcon outline />} />
                                                ),
                                                key: 'more',
                                                indicator: false,
                                                menu: {
                                                    items: [
                                                        props?.user?.role === 'ADMIN' && {
                                                            key: 'settings',
                                                            content: t('LABEL.SETTING'),
                                                            icon: <SettingsIcon color='primary' outline />,
                                                            onClick: (e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleDialogOpen('setting')
                                                            }
                                                        },
                                                        {
                                                            key: 'feedback',
                                                            content: t('LABEL.FEEDBACK'),
                                                            icon: <QuestionCircleIcon outline />,
                                                            onClick: (e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleDialogOpen('feedback')
                                                            }
                                                        },
                                                    ],
                                                }
                                            }
                                        ]}
                                        iconOnly
                                        accessibility={menuAsToolbarBehavior}
                                    />
                                </Flex.Item>
                            </Flex>
                        </BrowserView>
                    </Flex>
                </Flex.Item>
            </Flex>
            ) : null}
            <Flex ref={messageBoxRef} column padding='padding.medium' fill className='chat-body' id='ui-chat'>
                <Chat items={formatMessageItem(messageQuery.data || [])} />
            </Flex>
            <Flex.Item push>
                {isNotActive ? (
                    <Flex column gap="gap.small" vAlign="stretch" className='message-input'>
                        <Text align='center' content={!hasAccess ? t('LABEL.MESSAGE_BOX_NOT_ACCESS') : t('LABEL.MESSAGE_BOX_NOT_ACTIVE')} weight='semibold' />
                        <SendTemplateMessage  {...props} selected={selected!} />
                    </Flex>
                ) : (
                    <Flex column gap="gap.small" vAlign="stretch" className='message-input'>
                        {map(message.files, (file, idx) => (<Flex key={idx}>
                            <Attachment
                                style={{ maxWidth: '100%' }}
                                icon={<FileIcon type={file.type} />}

                                header={file.name}
                                action={{
                                    icon: <TrashCanIcon />,
                                    onClick: handleFileDelete,
                                    title: 'Download',
                                }}
                            />
                        </Flex>))}
                        <Flex.Item push>
                            <Flex column gap="gap.small">
                                {!hasAccess ? (<Text align='center' content={t('LABEL.MESSAGE_BOX_NOT_ACCESS')} weight='semibold' />) : null}
                                <TextArea ref={inputRef} onKeyDown={handleMessageKeyDown} disabled={!hasAccess} fluid inverted placeholder={t('INPUT.MESSAGE.PLACEHOLDER')} value={message.content} onChange={handleInputChange} />
                                <Flex space='between' >
                                    <Flex>
                                        {state.emoji && (<Flex id='emoji-picker' className='emoji-picker' ><Picker id='myDiv' data={data} onEmojiSelect={handleEmojiPicker} locale="nl" theme={props.theme === 'dark' ? 'dark' : 'light'} /></Flex>)}
                                        <Button icon={<PaperclipIcon outline />} text iconOnly onClick={handleUploadFile} disabled={!hasAccess} />
                                        <Button icon={<EmojiIcon outline />} text iconOnly onClick={toggleEmoji} disabled={!hasAccess} />
                                    </Flex>
                                    <Button primary onClick={sendMessage} icon={<SendIcon outline />} text iconOnly disabled={!hasAccess} />
                                </Flex>
                            </Flex>
                        </Flex.Item>
                    </Flex>
                )}
            </Flex.Item>
        </Flex>
    )
}
export default MessageBox