
import {
    Alert,
    Button,
    Dialog,
    Flex,
    Form,
    Input,
    Text
} from "@fluentui/react-northstar";
import { CONTACT } from "Helpers/Validations";
import { Props, UserModel } from "Models";
import { UserService } from "Services";
import { Formik, FormikHelpers } from "formik";
import { FC, useState } from "react";
export const EditContact: FC<Props & { open: boolean, onClose: () => void, item: UserModel, onSuccess: (item: UserModel) => void }> = (props) => {
    const { t } = props;
    const [state, setState] = useState<{
        alert: {
            open: boolean
            type: 'success' | 'danger' | 'info' | 'warning' | 'default'
            message: string
        }
    }>({
        alert: {
            open: false,
            type: 'success',
            message: ''
        }
    })

    const onSubmit = (user: UserModel, formik: FormikHelpers<UserModel>) => {
        formik.setSubmitting(true);
        if (user.uuid) {
            UserService.update(user.uuid, user).then((resp) => {
                if (resp?.status === 200) {
                    formik.setSubmitting(false);
                    formik.resetForm();
                    props.onSuccess(resp.data);
                    setState((prevState) => {
                        prevState.alert = {
                            open: true,
                            type: 'success',
                            message: resp.message
                        }
                        return { ...prevState }
                    });
                    setTimeout(() => {
                        setState((prevState) => {
                            prevState.alert = {
                                open: false,
                                type: 'success',
                                message: ''
                            }
                            return { ...prevState }
                        })
                        props.onClose()
                    }, 1000)
                }
            }).catch(console.error)
        }
    }
    return (
        <Formik
            initialValues={props.item}
            enableReinitialize
            validationSchema={CONTACT(!!props.item.uuid)}
            onSubmit={onSubmit}
        >
            {(formik) => (
                <Dialog
                    open={props.open}
                    className="add-contact"

                    header={
                        <Flex column>
                            <Text content={t('DIALOG.EDIT_CONVERSATION.TITLE')} />
                            <Text weight='light' size="small" content={t('DIALOG.EDIT_CONVERSATION.DESCRIPTION')} />
                        </Flex>
                    }
                    footer={
                        <Flex gap="gap.small" hAlign="end">
                            <Flex gap="gap.small" hAlign="end">
                                <Button disabled={formik.isSubmitting} onClick={props.onClose}>{t('BUTTON.CANCEL')}</Button>
                                <Button disabled={formik.isSubmitting} type='submit' primary onClick={formik.submitForm} loading={formik.isSubmitting}>{t('BUTTON.SAVE')}</Button>
                            </Flex>
                        </Flex>
                    }
                    content={
                        <Form>
                            <Flex gap="gap.small" column>
                                {state.alert.open && <Flex.Item>
                                    <Alert
                                        success={state.alert.type === 'success'}
                                        danger={state.alert.type === 'danger'}
                                        info={state.alert.type === 'info'}
                                        content={state.alert.message}
                                    />
                                </Flex.Item>
                                }
                                <Flex.Item size="size.full">
                                    <Flex gap="gap.small">
                                        <Flex.Item size="size.half">
                                            <Input
                                                required
                                                fluid
                                                value={formik.values.firstName}
                                                placeholder={t('INPUT.FIRST_NAME.PLACEHOLDER')}
                                                name="firstName"
                                                onChange={formik.handleChange}
                                                error={!!formik?.errors?.firstName}
                                            />
                                        </Flex.Item>
                                        <Flex.Item size="size.half">
                                            <Input
                                                required
                                                fluid
                                                value={formik.values.lastName}
                                                placeholder={t('INPUT.LAST_NAME.PLACEHOLDER')}
                                                name="lastName"
                                                onChange={formik.handleChange}
                                                error={!!formik?.errors?.lastName}
                                            />
                                        </Flex.Item>
                                    </Flex>
                                </Flex.Item>
                            </Flex>
                        </Form>
                    }
                />
            )}
        </Formik>
    )
}