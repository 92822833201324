import { Dispatch } from "@reduxjs/toolkit";
import { ResponseModel, UserModel } from "Models";
import { Http } from "Request";
import { Login } from "Store/Actions";


export default class AuthService {
    public static authenticate(params = {}): (dispatch: Dispatch) => Promise<ResponseModel<{ user: UserModel, token: string }>> {
        return (dispatch: Dispatch) => {
            return new Promise((resolve, reject) => {
                Http.post('authenticate', params)
                    .then((res) => {
                        dispatch(Login(res.data.data));
                        return resolve(res.data);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        }
    }
}