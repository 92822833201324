import { concat, debounce, filter, find, findIndex, first, forEach, groupBy, isEqual, last, map, orderBy, size, sortBy, sumBy, take, takeRight } from 'lodash';

export const FindIndex = findIndex;
export const Map = map;
export const GroupBy = groupBy;
export const Find = find;
export const Filter = filter;
export const ForEach = forEach;
export const SumBy = sumBy;
export const SortBy = sortBy;
export const IsEqual = isEqual;
export const Concat = concat;
export const GetSize = size;
export const TakeRight = takeRight;
export const Take = take;
export const Debounce = debounce;
export const OrderBy = orderBy;
export const Last = last;
export const First = first;
