import { Card, CloseIcon, Flex, Image, Pill, Skeleton, Text } from "@fluentui/react-northstar"
import { useQuery } from "@tanstack/react-query"
import { Props } from "Models"
import { JiraService } from "Services"
import config from "config"
import { filter, last, map } from "lodash"
import moment from "moment"
import { FC } from "react"
const MyImage: FC<{ item: any }> = (props: any) => {
    const { item } = props;
    if (!item) {
        return null
    }
    return <Image
        width='100%'
        style={{ margin: 'auto', marginBottom: '7px' }}
        loading='lazy'
        src={`${config.apiEndpoint}/api/v1/tasks/${item?.id}/images`}
    />
}

const renderContent = (content) => {
    if (!content || !Array.isArray(content)) return null;

    return content.map((item, index) => {
        if (item.type === 'paragraph') {
            return (
                <p key={index}>
                    {renderContent(item.content)}
                </p>
            );
        } else if (item.type === 'text') {
            if (item.marks && item.marks[0].type === 'link') {
                return (
                    <a key={index} href={item.marks[0].attrs.href} target="_blank" rel="noopener noreferrer">
                        {item.text}
                    </a>
                );
            }
            return item.text;
        } else {
            return null;
        }
    });
};

const MyCard: FC<{ item: any }> = (props: any) => {
    const { item } = props;
    const query = useQuery({
        queryKey: ['tasks', item?.key],
        enabled: !!item?.key,
        queryFn: () => JiraService.changeLogByKey(item?.key, { responseType: 'blob' }),
        select: (data) => data.data
    })

    if (query.isLoading) {
        return <Skeleton animation="pulse">
            <Flex column gap="gap.medium">
                <Flex gap="gap.medium" fill >
                    <Skeleton.Shape width="40px" height="20px" style={{ borderRadius: '20px' }} />
                    <Skeleton.Text />
                </Flex>
                <Flex gap="gap.medium" fill >
                    <Skeleton.Shape width="100%" height="180px" />
                </Flex>
            </Flex>
        </Skeleton>
    }

    const getText = (content: string) => {
        if (content === "Improvement") {
            return "Verbetering"
        }
        if (content === "New") {
            return "Nieuw"
        }
        return content;
    }

    return <Card>
        <Card.Body fitted>
            <Card.Header>
                <Flex gap="gap.small" column fill>
                    <Flex fill space="between">
                        <Pill style={{ marginLeft: 0, height: '1.6rem', minWidth: 0 }} className={query.data?.fields?.issuetype.name} content={getText(query.data?.fields?.issuetype.name)} />
                        <Text content={moment(query.data?.fields?.updated).fromNow()} style={{
                            display: 'flex',
                            alignItems: 'center',
                            opacity: 0.8
                        }} />
                    </Flex>
                </Flex>
            </Card.Header>
            <Card.Body>
                <Flex gap="gap.small" column fill>
                    <Flex fill>
                        <Text weight="semibold" size="medium" content={query.data?.fields?.summary} />
                    </Flex>
                    <Flex fill>
                        <MyImage item={last(filter(query.data?.fields?.attachment, item => item?.mimeType?.includes("image")))} />
                    </Flex>
                </Flex>
            </Card.Body>
            <Flex column gap="gap.small">
                <Text content={renderContent(query.data?.fields?.description?.content)}
                />
            </Flex>
        </Card.Body>
    </Card>
}

export const ChangeLogDialog: FC<Props & { open: boolean, onClose: () => void }> = (props) => {
    const { t, open, onClose } = props;
    const query = useQuery({
        queryKey: ['tasks'],
        queryFn: () => JiraService.changeLogs({ status: 'Publish' }),
        select: (data) => data.data
    })
    if (!open)
        return null
    return (
        <Flex className="change-log-popup visible" column gap="gap.small" >
            <Flex gap="gap.small" className="change-log-header" >
                <Text weight="semibold" size="large" content={t('DIALOG.CHANGE_LOG.TITLE')} />
                <CloseIcon className="change-log-close" onClick={onClose} />
            </Flex>
            <Flex className="change-log-body" gap="gap.small" column>
                {map(query.data, (item, idx) => <MyCard key={idx} item={item} />)}
            </Flex>
        </Flex>
    )
}