import { Provider, teamsTheme } from '@fluentui/react-northstar';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import Store from 'Store';
import App from './App';
import './index.css';
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Provider theme={teamsTheme}>
    <ReduxProvider store={Store}>
        <App />
    </ReduxProvider>
</Provider>);
