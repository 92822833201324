import {
  Spinner
} from "@fluentui/react-components";
import { Provider, teamsDarkV2Theme, teamsV2Theme } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import { useTeamsUserCredential, } from "@microsoft/teamsfx-react";
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { TeamsFxContext } from "components/Context";
import config from "config";
import "Libs/Localization";
import { i18next } from "Libs/Localization";
import { Authenticable, StateModel, TenantModel, UserModel } from "Models";
import moment from "moment";
import 'moment/locale/en-gb';
import 'moment/locale/nl';
import Dashboard from "pages/Dashboard";
import Privacy from "pages/Privacy";
import TermsOfUse from "pages/TermsOfUse";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Navigate, Route, HashRouter as Router, Routes } from "react-router-dom";
import Store from "Store";
import { Localization } from "Store/Actions";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 2000,
      retry: 0,
    },
  },
});


const App = (props) => {

  const [auth, setAuth] = useState<{ login: boolean, user: UserModel | null, token: string }>({
    login: false,
    user: null,
    token: ""
  })
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });

  useEffect(() => {
    app.initialize().then(() => {
      app.getContext().then((context) => {
        const lang = context.app.locale === 'nl-nl' ? 'nl' : 'en';
        i18next.changeLanguage(lang);
        moment.locale(context.app.locale);
        Store.dispatch(Localization(lang))
      });
      app.notifySuccess();
    }).catch((error) => {
      app.notifyFailure(error);
    });
  }, [])


  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential, auth, setAuth }}>
      <Provider theme={themeString === "dark" ? teamsDarkV2Theme : teamsV2Theme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            {loading ? (
              <Spinner style={{ margin: 100 }} />
            ) : (
              <Routes>
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/termsofuse" element={<TermsOfUse />} />
                <Route path="/dashboard" element={<Dashboard {...props} />} />
                <Route path="*" element={<Navigate to={"/dashboard"} />}></Route>
              </Routes>
            )}
          </Router>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </Provider>
    </TeamsFxContext.Provider>
  );
}
const mapStateToProps = (state: StateModel): Object => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    user: new Authenticable(state.Auth.user),
    tenant: TenantModel.parse(state.Auth.tenant),
    conversationType: state.App.conversationType || 'MY_CONVERSATIONS',
    filter: state.App.filter || 'ALL'
  };
};


export default connect(mapStateToProps)(withTranslation()(App));