
import { ConversationType, FilterType, InitialAppStateModel } from 'Models';
import { FixedHttp, Http } from 'Request';
import { ConfigActions, ConfigActionTypes } from '../Actions';

const initialState: InitialAppStateModel = {
    conversationType: 'MY_CONVERSATIONS',
    filter: 'ALL',
    theme: 'light',
    localization: 'en'
};

const App = (state = initialState, action: ConfigActions): InitialAppStateModel => {
    const { type, payload } = action;
    switch (type) {
        case ConfigActionTypes.ToggleConversationType: {
            return {
                ...state,
                conversationType: payload as ConversationType
            };

        }

        case ConfigActionTypes.ToggleFilter: {
            return {
                ...state,
                filter: payload as FilterType
            };

        }
        case ConfigActionTypes.Localization: {
            Http.defaults.headers.common['Accept-language'] = payload;
            FixedHttp.defaults.headers.common['Accept-language'] = payload;
            return {
                ...state,
                localization: payload
            };

        }
        default: {
            return state;
        }
    }
};

export default App;
