import { StateModel } from 'Models';

const storage: Storage = process.env.NODE_ENV === 'development' ? localStorage : localStorage;
export default class Stroage {
	public static LoadState = () => {
		try {
			const sessionState: any = storage.getItem('state') || {};
			const state = Object.assign({}, JSON.parse(sessionState));
			return state;
		} catch (err) {
			const params = new StateModel();
			return params;
		}
	};

	public static SaveState = (state: StateModel): void => {
		try {
			storage.clear();
			const sessionState = {
				Auth: state.Auth,
				App: state.App
			};

			storage.setItem('state', JSON.stringify(sessionState));
		} catch (err) {
		}
	};
}
