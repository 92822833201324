import { configureStore } from '@reduxjs/toolkit';
import Reducers from './Reducers';
import Stroage from './Stroage';

const Store = configureStore({
	reducer: Reducers,
	preloadedState: Stroage.LoadState(),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false
		})
});

Store.subscribe(() => {
	Stroage.SaveState(Store.getState());
});

export default Store;
