import { TenantModel, UserModel } from 'Models';

export enum AuthActionTypes {
    Login = '[Login] Action',
    Logout = '[Logout] Action',
    CheckAuth = '[CheckAuth] Action',
    LoadAuth = '[Update Profile] Action',
    LoadTenant = '[Update Tenant] Action',
    LoadProfileImage = '[Update Profile Image] Action',
    LoadDeviceToken = '[Load Device Token] Action'
}

export const Login = (payload: { user: UserModel, tenant: TenantModel, token: string }) => {
    return {
        type: AuthActionTypes.Login,
        payload
    };
};

export const Logout = () => {
    return {
        type: AuthActionTypes.Logout
    };
};

export const LoadAuth = (payload: { user?: UserModel, tenant?: TenantModel }) => {
    return {
        type: AuthActionTypes.LoadAuth,
        payload
    };
};

export const LoadDeviceToken = (payload: string) => {
    return {
        type: AuthActionTypes.LoadDeviceToken,
        payload
    };
};

export const CheckAuth = () => {
    return {
        type: AuthActionTypes.CheckAuth
    };
};

export class AuthActions {
    public type!: AuthActionTypes;
    public payload!: {
        token?: string,
        user?: UserModel,
        tenant?: TenantModel
    };
}
