import { InitialAuthStateModel, StateModel, TenantModel, UserModel } from 'Models';
import Stroage from 'Store/Stroage';
import { FixedHttp, Http } from '../../Request';
import { AuthActionTypes, AuthActions } from '../Actions';

const initialState: InitialAuthStateModel = {
	token: '',
	isAuthenticated: false,
	isExpired: false,
	user: new UserModel(),
	tenant: new TenantModel()
};

const Auth = (state = initialState, action: AuthActions): InitialAuthStateModel => {
	const { type, payload } = action;
	switch (type) {
		case AuthActionTypes.Login:
			Http.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`;
			FixedHttp.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`;
			Http.defaults.headers.common['X-Tenant-Id'] = payload.tenant?.uuid;
			FixedHttp.defaults.headers.common['X-Tenant-Id'] = payload.tenant?.uuid;
			return {
				...state,
				...payload,
				isAuthenticated: true,
				isExpired: false
			};
		case AuthActionTypes.Logout:
			Stroage.SaveState(new StateModel())
			return {
				...state,
				...initialState
			};

		case AuthActionTypes.LoadAuth: {
			return {
				...state,
				...payload
			};
		}

		// case AuthActionTypes.LoadDeviceToken: {
		// 	return {
		// 		...state,
		// 		deviceToken: payload
		// 	};
		// }

		case AuthActionTypes.CheckAuth:
			if (state.isAuthenticated) {
				Http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
				FixedHttp.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
			}
			return {
				...state
			};
		default:
			return state;
	}
};

export default Auth;
