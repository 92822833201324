import { ConversationType, FilterType } from "Models";

export enum ConfigActionTypes {
    ToggleConversationType = '[Config] ToggleConversationType',
    ToggleFilter = '[Config] ToggleFilter',
    Localization = '[Config] Localization'
}


export const ToggleConversationType = (payload: ConversationType) => {
    return {
        type: ConfigActionTypes.ToggleConversationType,
        payload
    };
};



export const ToggleFilter = (payload: FilterType) => {
    return {
        type: ConfigActionTypes.ToggleFilter,
        payload
    };
};

export const Localization = (payload: string) => {
    return {
        type: ConfigActionTypes.Localization,
        payload
    };
};

export class ConfigActions {
    type!: ConfigActionTypes;
    payload!: ConversationType | FilterType | string
}
